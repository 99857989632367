import React, { useState } from 'react'
import { Link } from 'gatsby'

import img1 from '../../assets/images/butik/butik_img1.png'
import img2 from '../../assets/images/butik/butik_img2.png'
import img3 from '../../assets/images/butik/butik_img3.png'
import img4 from '../../assets/images/butik/butik_img4.png'
import img5 from '../../assets/images/butik/butik_img5.png'
import img6 from '../../assets/images/butik/butik_img6.png'
import img7 from '../../assets/images/butik/butik_img7.png'

import imgTeamContact from '../../assets/images/img-team-2.png'

import SEO from '../../components/SEO'
import Header from '../../components/Header'
import TagsMarquee from '../../components/TagsMarquee'
import MintButtons from '../../components/MintButtons'
import ShareButtons from '../../components/ShareButtons'

import { theme, colors } from '../../constants/theme'

import {
  ProjectDetailsLayout,
  SectionCover,
  SectionIntro,
  SectionRichText,
  SectionCounterImage,
  SectionFooter,
  SectionNavigator,
  StickyButtons,
  Square,
} from '../../styles/ProjectDetailsLayout'
import { Content } from '../../styles/PageLayout'
import {
  HeadlineL,
  HeadlineM,
  TitleM,
  SubtitleM,
  ParagraphL,
  ParagraphM,
  ParagraphS,
} from '../../styles/Typography'

/**
 * A project details template, to be used in gatsby-node.
 * Includes sections that can be conditionally rendered.
 * @returns {StatelessComponent}
 */
const ProjectDetails = () => {
  const [selected, setSelected] = useState(0)

  const tags = [
    'Organizacija dogodkov',
    'Grafično oblikovanje',
    'Spletne strani',
    'Znamčenje',
    'Oglaševanje na družbenih omrežjih',
    'Video produkcija',
    'Organizacija dogodkov',
    'Grafično oblikovanje',
    'Spletne strani',
    'Znamčenje',
    'Oglaševanje na družbenih omrežjih',
    'Video produkcija',
  ]

  return (
    <>
      <SEO
        title="Mint and Square | Butik Festival"
        description="En naših lastnih projektov, ki se je prvič izvajal leta 2019. Festival elektronske glasbe, ki je ponosno majhen."
        image={img1}
      />
      <Header
        logoBackground={colors.purple}
        logoBorder={colors.purple}
        linkText={colors.purple}
        menuLines={colors.purple}
      />

      <ProjectDetailsLayout>
        {/* Cover Section - Aways visible */}
        <SectionCover>
          <div className="cover-image">
            <img src={img1} alt="Butik Festival" />
          </div>
          <Square background={colors.red} className="title">
            <ParagraphM color={colors.white}>Butik ENT</ParagraphM>
            <HeadlineM color={colors.purple}>Butik Festival</HeadlineM>
          </Square>
          <div className="tags">
            <TagsMarquee tags={tags} />
          </div>
        </SectionCover>

        {/* Intro Section - Aways visible */}
        <SectionIntro background={colors.purple}>
          <Content>
            <TitleM color={colors.white}>Izziv</TitleM>
            <ParagraphL color={colors.white} weight="300">
              V prvem letu privabiti mednarodno publiko in izpeljati festival brez (pre)velikega
              finančnega primankljaja.
            </ParagraphL>
          </Content>
          <Content>
            <TitleM color={colors.white}>Opis</TitleM>
            <ParagraphL color={colors.white} weight="300">
              En naših lastnih projektov, ki se je prvič izvajal leta 2019. Festival elektronske
              glasbe, ki je ponosno majhen.
            </ParagraphL>
          </Content>
        </SectionIntro>

        <div className="section-wrapper">
          {/* Sticky Share and Mint buttons - Aways visible */}
          <StickyButtons>
            <MintButtons isColumn selected={selected} setSelected={setSelected} />
            <ShareButtons isColumn />
          </StickyButtons>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText className="reduce-top-padding">
            <Content>
              {/* Example Title */}
              <h2>Kaj je Butik?</h2>

              {/* Example Paragraph */}
              <p>
                Butik je festival alternativne elektronske glasbe, ki se vsako poletje odvija na
                tolminskem Sotočju. Je majhen festival, z manjšimi odri, z glasbeno raznolikimi
                artisti (znotraj elektronske glasbe seveda), ki slovi po dobrem vzdušju.
              </p>

              {/* Example Title */}
              <h2>Branding</h2>

              {/* Example Paragraph */}
              <p>
                Ime Butik smo si izbrali šele, ko smo vedeli kakšen festival si želimo. Ime Butik v
                eni besedi pove kakšno izkušnjo lahko pričakujejo obiskovalci, hkrati pa je lahko
                zapomnljivo v vseh jezikih.
              </p>

              <p>
                Grafična podoba festivala je enostavna in minimalistična. Dva kroga sta hitro
                postala prepoznaven znak Butika, saj v njih vsakdo vidi nekaj drugega - od planeta
                Butik, do tesno povezane množice ljudi.
              </p>

              {/* Example Image */}
              <img src={img2} alt="Butik Festival" />

              {/* Example Title */}
              <h2>Oglaševanje</h2>

              {/* Example Paragraph */}
              <p>
                En izmed razlogov za uspeh prve edicije festivala je bil oglaševanje na družbenih
                omrežjih. Čeprav brez foto in video materiala, smo s pravilnimi sporočili in dobrim
                ciljanjem prišli do naših ciljnih kupcev iz cele Evrope - 40% gostov na prvi ediciji
                je prihajalo iz tujine.
              </p>

              <p>
                V prvem letu so pomemben del oglaševanja predstavljali tudi nišni mediji, ki so
                festivalu dodali kredibilnost.
              </p>

              <p>
                Drugo edicijo festivala smo morali zaradi korone žal prestaviti na naslednje leto,
                tako da smo vstopnice prodajali skoraj 2 leti. Festival je bil že drugo leto
                razprodan.
              </p>

              {/* Example Image */}
              <img src={img3} alt="Butik Festival" />

              {/* Example Title */}
              <h2>Organizacija</h2>

              {/* Example Paragraph */}
              <p>
                Na festivalu naša ekipa poleg marketinga skrbi še za pripravo glasbenega programa,
                spremljevalnega programa, dogovarjanje z vsemi partnerji in podizvajalci.
              </p>
            </Content>
          </SectionRichText>

          {/* CounterImages Section - Conditionally visible */}
          <SectionCounterImage>
            <div className="image">
              <img src={img4} alt="Butik Festival" />
            </div>
            <Square background={colors.purple} className="counter">
              <HeadlineL color={colors.white} font={theme.fontSecondary}>
                2
              </HeadlineL>
              <SubtitleM color={colors.white}>ediciji festivala</SubtitleM>
            </Square>
          </SectionCounterImage>
          <SectionCounterImage className="reverse">
            <div className="image">
              <img src={img5} alt="Butik Festival" />
            </div>
            <Square background={colors.red} className="counter">
              <HeadlineL color={colors.white} font={theme.fontSecondary}>
                10000+
              </HeadlineL>
              <SubtitleM color={colors.white}>sledilcev na družbenih omrežjih</SubtitleM>
            </Square>
          </SectionCounterImage>
          <SectionCounterImage>
            <div className="image">
              <img src={img6} alt="Butik Festival" />
            </div>
            <Square background={colors.purple} className="counter">
              <HeadlineL color={colors.white} font={theme.fontSecondary}>
                4000
              </HeadlineL>
              <SubtitleM color={colors.white}>obiskovalcev v prvih dveh letih</SubtitleM>
            </Square>
          </SectionCounterImage>
          <SectionCounterImage className="reverse">
            <div className="image">
              <img src={img7} alt="Butik Festival" />
            </div>
            <Square background={colors.red} className="counter">
              <HeadlineL color={colors.white} font={theme.fontSecondary}>
                80+
              </HeadlineL>
              <SubtitleM color={colors.white}>glasbenih izvajalcev</SubtitleM>
            </Square>
          </SectionCounterImage>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText>
            <Content>
              {/* Example Video */}
              <iframe
                src="https://www.youtube.com/embed/Fyg6YKP7eO8"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>

              {/* Example Paragraph */}
              <p>
                Fotografije: Jaka Rogelj <br /> Video: Aljaž Babnik
              </p>
            </Content>
          </SectionRichText>
        </div>

        {/* Footer Section - Aways visible */}
        <SectionFooter>
          <ParagraphL>Kako se ti je zdel ta projekt?</ParagraphL>
          <div className="mint-buttons">
            <MintButtons selected={selected} setSelected={setSelected} />
          </div>
          <div className="share-buttons">
            <ShareButtons />
          </div>
          <div className="contact">
            <img src={imgTeamContact} alt="Contact Team" />
            <ParagraphS weight="300">
              Živjo, sem Romina. Ustanoviteljica Mint & Square agencije in kreativna direktorica.
              Želiš več informacij o tej objavi, ali pa stopiti v kontakt? Lahko mi napišeš kar na{' '}
              {''}
              <a href="https://www.linkedin.com/in/romina-gerbec/" target="_blank">
                LinkedIn
              </a>{' '}
              👌
            </ParagraphS>
          </div>
        </SectionFooter>

        <SectionNavigator>
          <Link to="/project/nord-hard-seltzer">
            <ParagraphL>← Prejšnji projekt </ParagraphL>
          </Link>
          <Link to="/project/coronini-caffee">
            <ParagraphL>Naslednji projekt →</ParagraphL>
          </Link>
        </SectionNavigator>
      </ProjectDetailsLayout>
    </>
  )
}
export default ProjectDetails
